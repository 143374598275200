import React from "react";
import "./Message.css";
import I18n from "i18n-react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw'
import RainbowSDKService from "../services/RainbowSDKService";
import AdaptiveCard from "../AdaptiveCard/AdaptiveCard";
import {getKnownContact} from "../services/UtilsService";

export default class Message extends React.Component {
    saveToFile(blob, fileName) {
        let url = window.URL.createObjectURL(blob);
        let elementA = document.createElement("a");
        document.body.appendChild(elementA);
        elementA.style.display = "none";
        elementA.href = url;
        elementA.download = fileName;
        elementA.click();
        window.URL.revokeObjectURL(url);
    }

    fileDownload(fileId) {
        console.log("[Message] Got file with id : ", fileId);
        let fileDescriptor = RainbowSDKService.getFileDescriptor(fileId);
        RainbowSDKService.downloadFile(fileDescriptor).then( blob => {
            this.saveToFile(blob, fileDescriptor.fileName);
            console.log("[Message] File with id : ", fileId, " downloaded successfully");
        }).catch( err => {
            console.log("[Message] Unable to download the file with id : ", fileId, " err: ", err);
        })
    }

    messageContainer(message, full_message=true, show_name=true, cluster=false){
        let id = "message" + message.id;
        let content = message.alternativeContent ? message.alternativeContent.message : message.data;
        let side = message.side;
        let fileId = message.fileId;
        let date = message.date? new Date(message.date).toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'}) : undefined;
        let customType = message.customType? "-" + message.customType : "";
        let shrink = full_message? "" : " message-shrink";
        let sideClassName = "chat-message-" + side + customType + shrink + " message-content";
        let messageType = "regular";
        let fromFirstName = message.from && message.from.firstname;
        let contact = getKnownContact(message.from)

        if (typeof(content) === "string" && content.includes("http://adaptivecards.io/schemas/adaptive-card.json")) {
            content = JSON.parse(content)
            messageType = "adaptiveCards"
            sideClassName += "-adaptive-cards"
        }

        let iconSrc = "./img/headset.svg"
        let iconBgColor = "#6b479e"
        if (contact){
            iconSrc = `./img/${contact.icon}`
            iconBgColor = contact.iconColor
        }
        return (
            <div key={id} className={cluster?"chat-message-cluster":"chat-message-container"}>
                {show_name && side === 'L' && fromFirstName && <p className="firstname">{fromFirstName}</p>}
                <div className="message">
                    {
                        (full_message && side === 'L' && ["", "-outOfBusinessHours", "-unavailability"].includes(customType)) &&
                        <div className="icon-agent-container" style={{backgroundColor: iconBgColor}}>
                            <img className="icon-agent" src={iconSrc} alt={I18n.translate("alt_code_icon_agent")}/>
                        </div>
                    }
                    <div className={sideClassName}>
                        { (() => {
                            if (messageType === "adaptiveCards") {
                                return (
                                    <AdaptiveCard card={content} actionHandler={this.props.adaptiveCardActionsHandler}/>
                                )
                            }
                            else if (fileId) {
                                return (
                                    <button type="button" className="link-button" onClick={this.fileDownload.bind(this, fileId)}>
                                        <ReactMarkdown rehypePlugins={[rehypeRaw]} linkTarget="_blank">{content}</ReactMarkdown>
                                    </button>
                                )
                            }else if (customType !== "") {
                                return content
                            }
                            else {
                                return content.split('\n').map((line,i)=>
                                    <ReactMarkdown rehypePlugins={[rehypeRaw]} key={i} linkTarget="_blank">{line}</ReactMarkdown>
                                )
                            }
                        })()}
                        {
                            full_message && (date !== undefined) && <div className="message-timestamp">{date}</div>
                        }
                    </div>
                </div>
            </div>
        )
    }

    render () {
        if(this.props.message){
            return this.messageContainer(this.props.message)
        }else{
            const clusterLength=this.props.cluster.length
            return this.props.cluster.map((message, i)=>{
                return this.messageContainer(message, i+1===clusterLength, i===0, true)
            })
        }
    }
}
