import React from "react";
import { withCookies } from "react-cookie";
import RainbowSDKService from "./services/RainbowSDKService";
import UniversalLivechatService from "./services/UniversalLivechatService";
import WelcomeChat from "./WelcomeChat/WelcomeChat";
import LoadingScreen from "./LoadingScreen/LoadingScreen";
import Config from "./config/config.json";
import "./App.css";
import I18nService from "./services/I18nService";
import I18n from "i18n-react";

const States = {
  Initialising: 'Initialising',
  Initialised: 'Initialised',
  WaitOnUser: 'WaitOnUser',
  CreatingSession: 'CreatingSession',
  FillingInformationForm: 'FillingInformationForm',
  Authenticating: 'Authenticating',
  Authenticated: 'Authenticated',
  ChatArchived: 'ChatArchived',
  Expired: 'Expired',
  OutOfBusinessHours: 'OutOfBusinessHours',
  Unavailability: 'Unavailability',
  Error: 'Error'
}

class App extends React.Component {
  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      appState: States.Initialising,
      reasonForChat: null,
      room: cookies.get("livechat_room_id") || {}
    }
    console.log("[App] Initialised State : ", this.state);
    this.initiateChatSession = this.initiateChatSession.bind(this);
    this.setAppState = this.setAppState.bind(this);
    this.sessionVisibilityChange = this.sessionVisibilityChange.bind(this);
    this.handleReasonForChatChange = this.handleReasonForChatChange.bind(this);

    I18nService.init();
  }

  async componentDidMount() {
    document.addEventListener("visibilitychange", this.sessionVisibilityChange);

    try {
      await RainbowSDKService.start();

      if (Object.keys(this.state.room).length !== 0) {
        let session = await UniversalLivechatService.getSession(this.state.room);
        this.setState({reasonForChat: Config.chatService.reasonForChats.find(r=>r.name===session.reasonForChat)})
        let account = await RainbowSDKService.loginWithToken(session.token);
        console.log("[App] Account : ", account);
        console.log("[App] Successfully login");
        this.setAppState(States.Authenticated);
      } else {
        console.log("[App] Room cookies not found - Start a new session");
        this.setState({reasonForChat: Config.chatService.reasonForChats[0]})
        this.initiateChatSession(I18nService.getUsedLanguage(), Config.chatService.reasonForChats[0]).then()
      }
    }
    catch (err) {
      const { cookies } = this.props;
      cookies.remove("livechat_room_id");

      if (err.data && ["loginFailed", "sessionFailed"].includes(err.data.message)) {
        console.log("[App] Error While loading : ", err.data.message);

        await UniversalLivechatService.deleteSession(this.state.room.id, err.data);
        console.log("[App] session deleted - Starting a new session");
        this.setState({reasonForChat: Config.chatService.reasonForChats[0], room: {}})
        this.initiateChatSession(I18nService.getUsedLanguage(), Config.chatService.reasonForChats[0]).then()
      }
      else {
        console.log("[App] Unhandled error :", err);
        this.setAppState(States.Error);
      }
    }
  }

  sessionVisibilityChange(event){
    //console.log(event);
    if (Object.keys(this.state.room).length > 0) {
      //console.log(document.visibilityState);
      navigator.sendBeacon(Config.chatService.url
          + "/session-visibility-change?roomId="
          + this.state.room.id
          + "&status="
          + document.visibilityState
          + "&token="
          + UniversalLivechatService.getToken()
      )
    }
  }

  async initiateChatSession(language, reasonForChat) {
    try {
      let urlToken = UniversalLivechatService.getToken();
      if (!urlToken) {
        console.log("[App] No token found");
        this.setAppState(States.Error);
        return ;
      }

      let session = null
      let changedReason = true
      if(Object.keys(this.state.room).length === 0) {
        changedReason = false
        this.setAppState(States.CreatingSession)
        if(! reasonForChat.isBot) {
          let response = await UniversalLivechatService.getBusinessHours(language);
          if (!response.inBusinessHours) {
            this.setAppState(States.OutOfBusinessHours);
            return;
          }
          await UniversalLivechatService.checkAvailability(language, reasonForChat.name);
        }
        session = await UniversalLivechatService.createSession(language, reasonForChat.name);
        console.log("[App] Successfully got session");
        let account = await RainbowSDKService.loginWithToken(session.token);
        console.log("[App] Account : ", account);
        console.log("[App] Successfully login");
      }else{
        session = {
          room: this.state.room,
          guest_jid: RainbowSDKService.getConnectedUser().jid
        }
      }
      const joinedSession = await UniversalLivechatService.joinSession(
          session.room,
          session.guest_jid,
          {language: language, reasonForChat: reasonForChat.name},
          changedReason);

      const { cookies } = this.props;

      let sessionCookie = {
        path: "/",
        maxAge: joinedSession.ttl,
        sameSite: 'none'
      }
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        sessionCookie.sameSite = 'lax'
      } else {
        sessionCookie['secure'] = true
      }
      cookies.set("livechat_room_id", session.room, sessionCookie)
      this.setState({"room": session.room, "appState": States.Authenticated});
    } catch (err) {
      if (err.data && err.data.message === "noAgentAvailable") {
        console.log("[App] No agent available : ", err.data.message);
        this.setAppState(States.Unavailability);
      }
      else {
        console.log("[App] Error during initiateChatSession phase: ", err);
        this.setAppState(States.Error);
      }
    }
  }

  handleReasonForChatChange(event) {
    const reasonForChat = event.target.value;
    const reasonConfig = Config.chatService.reasonForChats.find(r=>r.name===reasonForChat)
    if(!reasonConfig){
      console.log("[App] unknown reason for chat");
      this.setAppState(States.Error);
      return ;
    }
    this.setState({reasonForChat: reasonConfig})
  }

  setAppState(state) {
    if (state === States.Error) {
      const { cookies } = this.props;
      cookies.remove("livechat_room_id");
      this.setState({"room": {}});
    }
    console.log("New State : ", state);
    this.setState({"appState": state});
  }

  render() {
    return (
      <div className="App">
        {(() => {
          if (this.state.appState === States.Initialising) {
            return <LoadingScreen message={I18n.translate("loading_screen_livechat_loading")}/>
          }
          else if (this.state.appState === States.Initialised) {
            return <WelcomeChat appState={this.state.appState} setAppState={this.setAppState} States={States} />
          }
          else {
            return <WelcomeChat room={this.state.room} appState={this.state.appState} handleReasonForChatChange={this.handleReasonForChatChange} reasonForChat={this.state.reasonForChat} setAppState={this.setAppState} reloadReasonForChat={this.reloadReasonForChat} States={States} disabled={true}/>
          }
        })()}
      </div>
    );
  }
}

export default withCookies(App);
