import React from "react";
import Message from "../Message/Message";
import SendMessageBar from "../SendMessageBar/SendMessageBar";
import RainbowSDKService from "../services/RainbowSDKService";
import Config from "../config/config.json";
import I18n from "i18n-react";
import I18nService from "../services/I18nService";
import LegalText from "../LegalText/LegalText";
import Typing from "../Typing/Typing";
import Information from "../Information/Information";
import "./WelcomeChat.css";
import CallComponent from "../CallComponent/CallComponent";
import UniversalLivechatService from "../services/UniversalLivechatService";
import {SubmitAction, OpenUrlAction} from "adaptivecards";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import {getKnownContact} from "../services/UtilsService";

const CallStates = {
    InComingCall: 'InComingCall',
    Answered: 'Answered',
    NoCall: "NoCall"
}

export default class WelcomeChat extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            messages: this.props.messages || [],
            room: this.props.room,
            contacts: [],
            audioInputs: [],
            audioOutputs: [],
            videoInputs: [],
            call: {},
            conferenceSession: {},
            typing: this.props.typing || false,
            disabled: this.props.disabled || false,
            callState: CallStates.NoCall
        }
        this.conversationRetrieved = false;

        this.setCallState = this.setCallState.bind(this);
        this.onResize = this.onResize.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        //this.unavailableMessage = this.unavailableMessage.bind(this);
        this.notifyIncomingCall = this.notifyIncomingCall.bind(this);

        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.messagesList = []
        this.isTyping = this.isTyping.bind(this);

        // Rainbow SDK Event
        this.isMessageToAdd = this.isMessageToAdd.bind(this);
        this.addRainbowMessageToList = this.addRainbowMessageToList.bind(this);
        this.getConversationMessages = this.getConversationMessages.bind(this);
        this.onMessageReceived = this.onMessageReceived.bind(this);
        this.onMessageReceiptReceived = this.onMessageReceiptReceived.bind(this);
        this.onBubbleUpdated = this.onBubbleUpdated.bind(this);
        this.onNewParticipantStatus = this.onNewParticipantStatus.bind(this);
        this.onWebRtcCallStateChanged = this.onWebRtcCallStateChanged.bind(this);
        this.onInvitationReceived = this.onInvitationReceived.bind(this);
        this.onBubbleConferenceStarted = this.onBubbleConferenceStarted.bind(this);
        this.onWebConferenceStatusUpdated = this.onWebConferenceStatusUpdated.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.onResize);
        document.addEventListener(RainbowSDKService.rainbowSDK.im.RAINBOW_ONNEWIMMESSAGERECEIVED, this.onMessageReceived);
        document.addEventListener(RainbowSDKService.rainbowSDK.im.RAINBOW_ONNEWIMRECEIPTRECEIVED, this.onMessageReceiptReceived);
        document.addEventListener(RainbowSDKService.rainbowSDK.im.RAINBOW_ONNEWPARTICIPANTSTATUS, this.onNewParticipantStatus);
        document.addEventListener(RainbowSDKService.rainbowSDK.contacts.RAINBOW_ONCONTACTINVITATIONRECEIVED, this.onInvitationReceived);
        document.addEventListener(RainbowSDKService.rainbowSDK.bubbles.RAINBOW_ONBUBBLEUPDATED, this.onBubbleUpdated);
        document.addEventListener(RainbowSDKService.rainbowSDK.webRTC.RAINBOW_ONWEBRTCCALLSTATECHANGED, this.onWebRtcCallStateChanged);
        document.addEventListener(RainbowSDKService.rainbowSDK.conferences.RAINBOW_ONBUBBLECONFERENCESTARTED, this.onBubbleConferenceStarted);
        document.addEventListener(RainbowSDKService.rainbowSDK.conferences.RAINBOW_ONWEBCONFERENCEUPDATED, this.onWebConferenceStatusUpdated);

        if (this.props.appState === this.props.States.Authenticated) {
            this.getConversationMessages().then();
            this.getNetworkContacts().then();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
        document.removeEventListener(RainbowSDKService.rainbowSDK.im.RAINBOW_ONNEWIMMESSAGERECEIVED, this.onMessageReceived);
        document.removeEventListener(RainbowSDKService.rainbowSDK.im.RAINBOW_ONNEWIMRECEIPTRECEIVED, this.onMessageReceiptReceived);
        document.removeEventListener(RainbowSDKService.rainbowSDK.im.RAINBOW_ONNEWPARTICIPANTSTATUS, this.onNewParticipantStatus);
        document.removeEventListener(RainbowSDKService.rainbowSDK.contacts.RAINBOW_ONCONTACTINVITATIONRECEIVED, this.onInvitationReceived);
        document.removeEventListener(RainbowSDKService.rainbowSDK.bubbles.RAINBOW_ONBUBBLEUPDATED, this.onBubbleUpdated);
        document.removeEventListener(RainbowSDKService.rainbowSDK.webRTC.RAINBOW_ONWEBRTCCALLSTATECHANGED, this.onWebRtcCallStateChanged)
        document.removeEventListener(RainbowSDKService.rainbowSDK.conferences.RAINBOW_ONBUBBLECONFERENCESTARTED, this.onBubbleConferenceStarted)
        document.removeEventListener(RainbowSDKService.rainbowSDK.conferences.RAINBOW_ONWEBCONFERENCEUPDATED, this.onWebConferenceStatusUpdated)
    }

    onInvitationReceived(event) {
        let invitation = event.detail
        console.log(invitation)
        RainbowSDKService.acceptInvitationReceived(invitation.id).then(_ => {
            console.log("Invitation successfully accepted")
        }).catch(e => {
            console.log(`Unable to accept invitation: ${e}`)
        })
    }

    onBubbleConferenceStarted(event) {
        let bubble = event.detail
        this.setState({"callState": CallStates.InComingCall, "call": bubble});
    }

    onWebConferenceStatusUpdated(event) {
        let conferenceSession = event.detail
        this.setState({"conferenceSession": conferenceSession});
    }

    onMessageReceived(event) {
        let message = event.detail.message;
        let conversation = event.detail.conversation;
        if (conversation && message) {
            console.log("[WelcomeChat] Receive a message : ", message);
            RainbowSDKService.markMessageFromConversationAsRead(conversation, message);
            let commands = message.data.split(" ").filter( function(e) { return e.trim().length > 0; } );

            if(!conversation.room){
                RainbowSDKService.sendMessageToConversation(
                    conversation.id,
                    I18n.translate("no_message_in_conversation") + " \"" + this.state.room.name + "\"")
                RainbowSDKService.sendMessageToBubble(
                    this.state.room,
                    I18n.translate("continue_conversation_here"),
                    'low')
            }else if (commands[0] === "#invite") {
                this.props.handleReasonForChatChange({target:{value:commands[1]}})
            }else{
                this.addContactToNetwork(message.from)
                this.addRainbowMessageToList(message);
            }

        }
    }

    onMessageReceiptReceived(event) {
        if (event.detail.evt === "server" && event.detail.conversation.room) {
            console.log("[WelcomeChat] messages : ", event.detail.message);
            this.addRainbowMessageToList(event.detail.message);
        }
    }

    onNewParticipantStatus(event) {
        let status = event.detail.status;
        let room = event.detail.participant.room;
        if (status === "composing" && room) {
            this.setState({"typing": true});
        } else if (status === "active"){
            this.setState({"typing": false});
        }
    }

    onBubbleUpdated(event){
        let bubble = event.detail;
        this.setState({room: bubble})
        console.log("[WelcomeChat] onBubbleUpdated", bubble.status );
        if (bubble.status === "unsubscribed"){
            if (this.props.appState === this.props.States.Initialised) {
                this.props.setAppState(this.props.States.Expired);
            } else if (this.props.appState === this.props.States.Authenticated){
                this.props.setAppState(this.props.States.ChatArchived);
            }
        }
    };

    async onWebRtcCallStateChanged(event) {
        const call = event.detail;
        const callStatus = call.status.value;
        const callId = call.id;
        console.log('[Call] WebRTC call state changed', callStatus, callId, call);
        if (call.remoteMedia === 3 && callStatus === 'active') {
            RainbowSDKService.rainbowSDK.webRTC.showLocalVideo();
        }

        // To answer 1v1 call
        if (call.status.value === "incommingCall") {
            // You have an incoming call, do something about it:
            // Detect the type of incoming call
            if (call.remoteMedia === 1) {
                let audioInputs = [];
                let audioOutputs = [];
                let videoInputs = [];
                try {
                    let stream = await navigator.mediaDevices.getUserMedia({audio: true, video: true})
                    /* Stream received which means that the user has authorized the application to access to the audio and video devices. Local stream can be stopped at this time */
                    stream.getTracks().forEach(function(track) {
                        track.stop();
                    });

                    /*  Get the list of available devices if we need to implement device picker*/
                    let devices = await navigator.mediaDevices.enumerateDevices();
                    devices.forEach(function(device) {
                        switch (device.kind) {
                            case "audioinput":
                                // This is a device of type 'microphone'
                                audioInputs.push(device);
                                break;
                            case "audiooutput":
                                // This is a device of type 'speaker'
                                audioOutputs.push(device);
                                break;
                            case "videoinput":
                                // This is a device of type 'camera'
                                videoInputs.push(device);
                                break;
                            default:
                                break;
                        }
                    });

                    this.notifyIncomingCall();
                    this.setState({"audioInputs": audioInputs, "audioOutputs": audioOutputs, "videoInputs": videoInputs, "callState": CallStates.InComingCall, "call": call});
                }
                catch(error) {
                    /* In case of error when authorizing the application to access the media devices */
                    console.error("[Call] Cannot get user media ", error);
                    await UniversalLivechatService.sendInformationMessageToUser(this.state.room.id, "getUserMedia", I18nService.getUsedLanguage());
                }
            }
        }
        else if (call.status.value === "Unknown") {
            this.setState({"callState": CallStates.NoCall, "call": call})
        }
    }

    addMessageToList = (message) => {
        this.setState({"messages": this.state.messages.concat(message)});
        this.scrollToBottom();
    }

    notifyIncomingCall() {
        if (!('Notification' in window)) {
            console.log("[Call] This browser does not support notifications");
        }
        else if (Notification.permission === 'granted') {
            console.log("[Call] You have access to notification");
            new Notification(I18n.translate("notification_incoming_call"));
        }
        else if (Notification.permission !== 'denied') {
            console.log("[Call] Notification not granted but not denied so request them");
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    new Notification(I18n.translate("notification_incoming_call"));
                }
            })
        }
        else {
            console.log("[Call] Notification denied");
        }
    }

    async getConversationMessages() {
        if (!this.conversationRetrieved) {
            let conversation = await RainbowSDKService.getConversationByBubble(this.props.room)
            this.conversationRetrieved = true;
            if (conversation !== null){
                RainbowSDKService.getMessagesFromConversation(conversation).then(conversationWithHistory => {
                    let filteredMessages = conversationWithHistory.messages.filter(message => {
                        return this.isMessageToAdd(message);
                    })
                    if (filteredMessages.length > 0) {
                        this.setState({"messages": filteredMessages});
                    }
                }).catch( e => {
                    console.error("[WelcomeChat] Got error while retrieving messages from bubble : ", e);
                });
            }
        }
    }

    async getNetworkContacts() {
        if (this.state.contacts.length === 0) {
            const contacts = await RainbowSDKService.getNetworkContacts()
            this.setState({contacts: contacts})
        }
    }

    addContactToNetwork(contact) {
        if(getKnownContact(contact) && !this.state.contacts.find(c=>c.dbId===contact.dbId)){
            RainbowSDKService.addToNetwork(contact.dbId).then(()=>this.getNetworkContacts()).catch(e=>{
                if(e.message.includes('joinContactInvitation')){

                }else{
                    throw e
                }
            })
        }
    }

    addRainbowMessageToList = (message) => {
        if(this.isMessageToAdd(message)) {
            let alreadyExist = this.state.messages.find((existingMessage) => {
                return existingMessage.id === message.id;
            });
            if (!alreadyExist) {
                this.setState({"messages": this.state.messages.concat(message)});
                this.scrollToBottom();
            }
        }
    }

    isMessageToAdd = (message) => {
        if (message.side === "ADMIN" || message.urgency === "low"){
            return false
        } else if (message.side === "L" &&
            Config.chatService.hiddenMessagePrefix.find(prefix => message.data.startsWith(prefix)))
        {
            return false
        }
        return true;
    }

    handleAdaptiveCardActions = (action) => {
        let data = action.data;
        if ((action instanceof SubmitAction) && ("rainbow" in data && data.rainbow.type === "messageBack")) {
            RainbowSDKService.sendMessageToBubble(this.props.room, data.rainbow.text);
        } else if (action instanceof OpenUrlAction) {
            window.open(action.url);
        } else {
            console.log("You clicked on an unsupported button " + action.title);
        }
    }

    handleMessageChange(event) {
        if (this.chatFlow === "rainbowOffice" && this.stepsCursor === "7") {
            this.flowData.companyName = event.target.value;
        }
    }

    async isTyping() {
        this.setState({"typing" : true});
        await new Promise(res => setTimeout(res, 700));
        this.setState({"typing" : false});
        await new Promise(res => setTimeout(res, 300));
    }



    scrollToBottom (fromSelect = false, timeout = 100) {
        if (!fromSelect && this.stepsCursor === "4" && (this.props.appState === this.props.States.Initialised)) {
            document.getElementById("message3").scrollIntoView();
            return;
        }
        // We scroll after a short delay, to be sure our component is rendered
        setTimeout(() => {
            let target = document.getElementById("chat");
            target.scrollTop = target.scrollHeight;
        },timeout);
    }

    onResize() {
        console.log("[WelcomeChat] Window resized, forced to refresh for height calculation");
        this.forceUpdate();
    }

    setCallState(callState) {
        let tempState = {"callState": callState}
        if (callState === CallStates.NoCall) {
            tempState["call"] = {}
        }
        this.setState(tempState);
    }

    render() {
        this.messagesList = []
        this.state.messages.forEach(message => {
            const messageListLength = this.messagesList.length
            const last_message = messageListLength > 0 && this.messagesList[messageListLength-1]
            if(messageListLength > 0 && last_message.fromJid === message.from.jid){
                if (last_message.type === 'message'){
                    this.messagesList[messageListLength-1] = {
                        'type': 'cluster',
                        'fromJid': message.from.jid,
                        'cluster': [last_message.message, message]
                    }
                }else{
                    this.messagesList[messageListLength-1].cluster.push(message)
                }
            }else{
                this.messagesList.push({
                    'type': 'message',
                    'fromJid': message.from.jid,
                    'message': message
                })
            }
        });

        const isLoading = this.props.appState === this.props.States.CreatingSession
        return (
            <>
                { this.state.callState !== CallStates.NoCall &&
                    <CallComponent callState={this.state.callState} setCallState={this.setCallState} CallStates={CallStates} call={this.state.call} conferenceSession={this.state.conferenceSession}/>
                }
                {
                    isLoading && <LoadingScreen message={I18n.translate("loading_screen_livechat_loading")}/>
                }
                <div id="chat" className="chat-area" hidden={isLoading}>
                    <div id="chat-listOfMessages" className="chat-messages">
                        {this.messagesList.map((message, key)=>{
                            if (message.type === 'message'){
                                return <Message message={message.message}
                                                key={key}
                                                adaptiveCardActionsHandler={this.handleAdaptiveCardActions}/>
                            }else{
                                return <Message cluster={message.cluster}
                                                key={key}
                                                adaptiveCardActionsHandler={this.handleAdaptiveCardActions}/>
                            }
                        })}
                    </div>
                    {(this.stepsCursor === "4" && (this.props.appState === this.props.States.Initialised)) &&
                        <LegalText/>}
                </div>
                {  this.state.typing  && <Typing /> }
                {  this.props.appState !== this.props.States.CreatingSession && (()=> {
                    if (this.props.appState === this.props.States.Initialised) {
                        /* Placeholder to create prechat workflow like in ALE-Chat*/
                        return <SendMessageBar bubble={this.props.room}
                                               disabled={this.state.disabled}
                                               handleMessageChange={() => {
                                               }}
                                               buttonAction={() => {
                                               }}/>
                    }
                    else if (this.props.appState === this.props.States.Authenticated) {
                        return <SendMessageBar bubble={this.props.room} />

                    }
                    else if ([this.props.States.OutOfBusinessHours,
                        this.props.States.CreatingSession,
                        this.props.States.Unavailability].includes(this.props.appState)) {
                        return <SendMessageBar disabled={this.state.disabled} />
                    }
                    else if (this.props.appState === this.props.States.ChatArchived) {
                        return <Information message={I18n.translate("chat_conversation_closed_message")}/>
                    }
                    else if (this.props.appState === this.props.States.Expired) {
                        return <Information message={I18n.translate("chat_expired")}/>
                    }
                    else if (this.props.appState === this.props.States.Error) {
                        return <Information message={I18n.translate("unknown_error")}/>
                    }
                })()}
            </>
        )
    }
}
