import React from "react";
import RainbowSDKService from "../services/RainbowSDKService";
import I18n from "i18n-react";
import "./CallComponent.css";

export default class CallComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            callState: this.props.callState,
            isMuted: false,
            isSharing: false
        }
    }

    componentDidMount() {
        document.addEventListener(RainbowSDKService.rainbowSDK.webRTC.RAINBOW_ONWEBRTCCALLSTATECHANGED, this.onCallStateChange);
        document.addEventListener(RainbowSDKService.rainbowSDK.webRTC.RAINBOW_ONWEBRTCERRORHANDLED, this.onWebRTCErrorHandled);
        document.addEventListener(RainbowSDKService.rainbowSDK.webRTC.RAINBOW_ONWEBRTCTMEDIAERROROCCURED, this.onWebRTCGetUserMediaErrorOccured)
    }

    componentWillUnmount() {
        document.removeEventListener(RainbowSDKService.rainbowSDK.webRTC.RAINBOW_ONWEBRTCCALLSTATECHANGED, this.onCallStateChange);
    }

    onWebRTCGetUserMediaErrorOccured = (event) => {
        let error = event.detail;
        console.error("ERROR MEDIA: ", error);
        // Do something when this error occurs
    };

    onWebRTCErrorHandled = (error) => {
        console.error("ERROR Web RTC Handled: ", error);
    }

    onCallStateChange = (call) => {
        console.log("[CallComponent] Call in state changed : ", call)
        this.setState({"isSharing": call.detail.localMedia === 9});
    }

    answerCall = () => {
        this.props.setCallState(this.props.CallStates.Answered);
        RainbowSDKService.answerInAudio(this.props.call);
        // MediaStreamTrack.mute = false;
        // let audioTracks = localMediaStream.getAudioTracks();
        // // if MediaStream has reference to microphone
        // if (audioTracks[0]) {
        //     audioTracks[0].enabled = false;
        // }
    }

    releaseCall = () => {
        RainbowSDKService.releaseCall(this.props.call);
        this.props.setCallState(this.props.CallStates.NoCall);
    }

    muteCall = () => {
        const call = RainbowSDKService.muteCall(!this.state.isMuted, this.props.call)
        console.log("[CallComponent] mute call : ", call);
        // this.setState({"isMuted": (call.status.value === 'active' && call.isMuted)});
        this.setState({"isMuted": call.isMuted});
    }

    shareScreen = () => {
        // It will be best if possible to keep 1 to 1 call and conferences
         RainbowSDKService.addSharingToCall(!this.state.isSharing, this.props.call);
        //RainbowSDKService.addSharingToConference(!this.state.isSharing, this.props.conferenceSession)
    }

    render() {
        console.log("call state : ", this.state.callState);
        let buttonMuteClasses = "call-area__button"
        let buttonSharingClasses = "call-area__button"
        if (this.state.isMuted) {
            buttonMuteClasses += " button-selected"
        }
        if (this.state.isSharing) {
            buttonSharingClasses += " button-selected"
        }

        return (
          <div className={"callAreaComponent call-media-container"}>
              <div className="call-area-main-video-container">
                  <img className="call-area-icon-agent" src="img/headset.svg" alt={I18n.translate("alt_code_icon_agent")}/>
              </div>
              { this.props.callState === this.props.CallStates.InComingCall &&
                  <div className="coming-call-container">
                      <button className="button-coming-call answer" id="answer-call" onClick={this.answerCall}>
                          <svg className="align-svg" role="img" aria-hidden="true" viewBox="0 0 24 24">
                              <path d="M12 14a3 3 0 003-3V5a3 3 0 00-6 0v6a3 3 0 003 3zm-1-9a1 1 0 012 0v6a1 1 0 01-2 0zm6 6a5 5 0 01-10 0H5a7 7 0 006 6.92V21h2v-3.08A7 7 0 0019 11z" fill="#fff"/>
                          </svg>
                          {I18n.translate("answer")}
                      </button>
                      <button className="button-coming-call decline" id="close-call" onClick={this.releaseCall}>
                          <svg-img iconid="hang-up" tooltipdelay="700" >
                              <svg className="align-svg" role="img" id="hang-up" viewBox="0 0 24 24">
                                  <path d="M23.71 10.84a16.59 16.59 0 00-23.42 0 1 1 0 000 1.42l2.38 2.37a1 1 0 001.41 0 11.64 11.64 0 013.48-2.43 1 1 0 00.68-.95A25.7 25.7 0 008 8.55c.35-.1.69-.19 1.05-.26h.24c.34-.06.69-.11 1-.15h.35C11.14 8 11.57 8 12 8s.86 0 1.29.06h.35c.35 0 .7.09 1 .15h.24c.36.07.7.16 1.05.26a25.7 25.7 0 00-.21 2.7 1 1 0 00.68.95 11.45 11.45 0 013.47 2.44 1 1 0 00.71.29 1 1 0 00.71-.3l2.38-2.37a1 1 0 00.04-1.34zm-17.49-.25a13.77 13.77 0 00-2.81 1.95l-1-1 .2-.18.39-.21c.25-.2.51-.39.77-.57l.23-.22c.36-.23.72-.45 1.1-.66h.08c.29-.15.58-.28.88-.41.08.38.13.86.16 1.3zm14.37 1.95a13.77 13.77 0 00-2.81-1.95c0-.44.08-.92.12-1.34.29.13.59.26.87.41l.1.05c.37.2.73.42 1.09.65l.32.22c.25.18.51.37.76.57l.31.25.2.18z" fill="#fff"/>
                                  <path d="M9.93 17.52l.37.36 1.7 1.7-2.07-2.06M13 11h-2v6.17l-3.24-3.24-1.42 1.41L12 21l5.66-5.66-1.42-1.41L13 17.17V11z" fill="#f3483f" />
                              </svg>
                          </svg-img>
                          {I18n.translate("decline")}
                      </button>
                  </div>
              }
              { this.props.callState === this.props.CallStates.Answered &&
                  <div className="call-area-action-buttons">
                      <div className="call-area-buttons-container">
                          <button className={buttonMuteClasses} id="call-mute" onClick={this.muteCall}>
                              <svg role="img" id="micro">
                                  { this.state.isMuted &&
                                    <path id="mic-off--filled" viewBox="0 0 24 24" d="M19 11h-1.7a5.11 5.11 0 01-.43 2.05l1.23 1.23A6.59 6.59 0 0019 11zm-4 .17V5a3 3 0 00-6 0v.18zM4.27 3L3 4.27l6 6V11a3 3 0 003 3 2.82 2.82 0 00.65-.08l1.66 1.66a5.5 5.5 0 01-2.31.52A5.19 5.19 0 016.7 11H5a7 7 0 006 6.72V21h2v-3.28a7 7 0 002.54-.9L19.73 21 21 19.73z" fill="#fff"/>
                                  }
                                  { !this.state.isMuted &&
                                      <path d="M12 14a3 3 0 003-3V5a3 3 0 00-6 0v6a3 3 0 003 3zm-1-9a1 1 0 012 0v6a1 1 0 01-2 0zm6 6a5 5 0 01-10 0H5a7 7 0 006 6.92V21h2v-3.08A7 7 0 0019 11z" fill="#fff"/>
                                  }
                              </svg>
                          </button>

                          <button className={buttonSharingClasses} id="call-add-sharing" onClick={this.shareScreen}>
                              <svg role="img" id="share_screen" viewBox="0 0 24 24">
                                  <path d="M13 9.13c-3.89.54-5.44 3.2-6 5.87 1.39-1.87 3.22-2.72 6-2.72v2.19l4-3.74L13 7z" fill="#fff"/>
                                  <path d="M20 6v10H4V6h16m0-2H4a2 2 0 00-2 2v10a2 2 0 002 2h16a2 2 0 002-2V6a2 2 0 00-2-2zm-3 17H7l1-2h8l1 2z" fill="#fff"/>
                              </svg>
                          </button>

                          <button className="call-area__button last-button" id="call-drop" onClick={this.releaseCall}>
                              <svg role="img" id="hang-up" viewBox="0 0 24 24">
                                  <path d="M23.71 10.84a16.59 16.59 0 00-23.42 0 1 1 0 000 1.42l2.38 2.37a1 1 0 001.41 0 11.64 11.64 0 013.48-2.43 1 1 0 00.68-.95A25.7 25.7 0 008 8.55c.35-.1.69-.19 1.05-.26h.24c.34-.06.69-.11 1-.15h.35C11.14 8 11.57 8 12 8s.86 0 1.29.06h.35c.35 0 .7.09 1 .15h.24c.36.07.7.16 1.05.26a25.7 25.7 0 00-.21 2.7 1 1 0 00.68.95 11.45 11.45 0 013.47 2.44 1 1 0 00.71.29 1 1 0 00.71-.3l2.38-2.37a1 1 0 00.04-1.34zm-17.49-.25a13.77 13.77 0 00-2.81 1.95l-1-1 .2-.18.39-.21c.25-.2.51-.39.77-.57l.23-.22c.36-.23.72-.45 1.1-.66h.08c.29-.15.58-.28.88-.41.08.38.13.86.16 1.3zm14.37 1.95a13.77 13.77 0 00-2.81-1.95c0-.44.08-.92.12-1.34.29.13.59.26.87.41l.1.05c.37.2.73.42 1.09.65l.32.22c.25.18.51.37.76.57l.31.25.2.18z" fill="#fff"/>
                                  <path d="M9.93 17.52l.37.36 1.7 1.7-2.07-2.06M13 11h-2v6.17l-3.24-3.24-1.42 1.41L12 21l5.66-5.66-1.42-1.41L13 17.17V11z" fill="#f3483f" />
                              </svg>
                          </button>
                      </div>
                  </div>
              }
          </div>
        );
    }
}
