import Config from "../config/config.json";

function parse(str) {
    let args = [].slice.call(arguments, 1),
        i = 0;

    return str.replace(/%s/g, () => args[i++]);
}

function getKnownContact(contact){
    if(contact) {
        const fromName = contact._displayName;
        return Config.chatService.contacts.find(c => c.name === fromName)
    }
}

export {
    parse,
    getKnownContact
}
