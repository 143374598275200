import rainbowSDK from "rainbow-web-sdk";
import Config from "../config/config.json";


class RainbowSDKService {
    start() {
        console.log("[RainbowSDKService] starting");
        this.rainbowSDK = rainbowSDK;
        /* Add window object to allow debugging from the console */
        window.rainbowSDK = rainbowSDK;

        return new Promise((resolve, reject) => {
            let onReady = () => {
                console.log('[RainbowSDKService] Rainbow SDK Ready !');
                resolve();
            }
            let onLoaded = () => {
                console.log('[RainbowSDKService] Rainbow SDK Loaded !');
            }

            document.addEventListener(rainbowSDK.RAINBOW_ONREADY, onReady);
            document.addEventListener(rainbowSDK.RAINBOW_ONREADY, onLoaded);

            rainbowSDK.load();
            rainbowSDK
                .initialize(Config.rainbow.application.appID, Config.rainbow.application.appSecret)
                .then(() => {
                    console.log('[RainbowSDKService] SDK is well initialized');
                })
                .catch(err => {
                    console.log('[RainbowSDKService] Error during initialisation : ', err);
                    reject();
                });
        })
    }

    login(email, password) {
        return new Promise((resolve, reject) => {
            rainbowSDK.connection.signin(email, password).then( account => {
                resolve(account);
            }).catch(error => {
                console.log("[RainbowSDKService] Error login in ", error);
                reject({
                    "data": {
                        "message": "Error Login"
                    }
                });
            })
        })
    }

    loginWithToken(token) {
        return new Promise((resolve, reject) => {
            rainbowSDK.connection.signinOnRainbowHostedWithToken(token, Config.rainbow.server.host).then(account => {
                this.checkWebRtcCapabilities()
                    .then( _ => console.log("[RainbowSDKService] WebRtc Capabilities check done"))
                    .catch( (e) => console.log("[RainbowSDKService] WebRtc Capabilities check failed: ", e))
                resolve(account);
            }).catch(error => {
                console.log("[RainbowSDKService] Error login in with token ", error);
                reject({
                    "data": {
                        "message": "sessionFailed"
                    }
                })
            });
        });
    }

    acceptInvitationReceived(invitationId) {
        return rainbowSDK.contacts.acceptInvitation(invitationId);
    }

    getConversationByBubble(bubble) {
        return rainbowSDK.conversations.getConversationByBubbleId(bubble.id);
    }

    getConnectedUser() {
        return rainbowSDK.contacts.getConnectedUser();
    }

    getNetworkContacts() {
        return rainbowSDK.contacts.getNetworkContacts();
    }

    addToNetwork (contactId) {
        return rainbowSDK.contacts.addToNetwork(contactId)
    }

    getMessagesFromConversation(conversation){
        return rainbowSDK.im.getMessagesFromConversation(conversation.id, 100);
    }

    markMessageFromConversationAsRead(conversation, message) {
        rainbowSDK.im.markMessageFromConversationAsRead(conversation.id, message.id);
    }

    getFileDescriptor(fileId) {
        return rainbowSDK.fileStorage.getFileDescriptorFromId(fileId);
    }

    downloadFile(fileDescriptor) {
        return rainbowSDK.fileStorage.downloadFile(fileDescriptor);
    }

    sendMessageToBubble(bubble, message, urgency='std') {
        rainbowSDK.im.sendMessageToBubble(bubble, message, null, null, urgency);
    }

    sendMessageToConversation(conversationId, message) {
        rainbowSDK.im.sendMessageToConversation(conversationId, message);
    }

    async checkWebRtcCapabilities() {
        this.hasACamera = rainbowSDK.webRTC.hasACamera();
        this.canMakeAudioVideoCall = rainbowSDK.webRTC.canMakeAudioVideoCall();
        this.canMakeDesktopSharingCall = await rainbowSDK.webRTC.canMakeDesktopSharingCall();
        console.log('[RainbowSDKService] Camera available: ', this.hasACamera);
        console.log('[RainbowSDKService] Can make Audio / Video calls: ', this.canMakeAudioVideoCall);
        console.log('[RainbowSDKService] Can make desktop sharing calls: ', this.canMakeDesktopSharingCall);
    }

    answerInAudio (call) {
        console.log('[RainbowSDKService] Call id : ', call.id)
        this.rainbowSDK.webRTC.answerInAudio(call.id);
    }

    joinConference (bubble) {
        console.log('[RainbowSDKService] bubble jid : ', bubble.jid)
        this.rainbowSDK.conferences.startOrJoinWebConference(bubble);
    }

    addSharingToCall(addSharing, call) {
        if (addSharing) {
            return this.rainbowSDK.webRTC.addSharingToCall(call.id);
        }
        else {
            return this.rainbowSDK.webRTC.removeSharingFromCall(call.id);
        }
    }

    addSharingToConference(addSharing, conferenceSession) {
        if (addSharing) {
            return this.rainbowSDK.conferences.addSharingToConference(conferenceSession);
        }
        else {
            return this.rainbowSDK.conferences.removeSharingFromConferenceSession(conferenceSession);
        }
    }

    muteCall(mustMute, call) {
        if (mustMute) {
            return this.rainbowSDK.webRTC.muteAudioCall(call.id);
        }
        else {
            return this.rainbowSDK.webRTC.unmuteAudioCall(call.id);
        }
    }

    releaseCall(call) {
        this.rainbowSDK.webRTC.release(call.id);
    }
}

export default new RainbowSDKService();
