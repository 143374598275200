import Config from "../config/config.json";

class UniversalLivechatService {
    getToken() {
        return new URLSearchParams(window.location.search).get("token");
    }

    checkAvailability(language="en", reason) {
        return new Promise((resolve, reject) => {
            fetch(Config.chatService.url + "/availability?language="+language+"&reason="+reason+"&token="+this.getToken(), {
                method: "get",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                }
            }).then(response => {
                if (response.status === 200) {
                    response.json().then(jsonResponse => {
                        resolve(jsonResponse)
                    }).catch(err => {
                        console.log(err);
                        reject({"data": {"message": "noAgentAvailable"}})
                    });
                } else {
                    reject({"data": {"message": "noAgentAvailable"}})
                }
            }).catch(err => {
                console.log("[UniversalLivechatService] Error trying to find available agents => ", err);
                reject(err);
            })
        });
    }

    createSession(language, reasonForChat) {
        return new Promise((resolve, reject) => {
            fetch(
                Config.chatService.url +
                "/create-session?token=" + this.getToken(),
                {
                    method: "post",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({language: language, reasonForChat: reasonForChat})
                }
            ).then(response => {
                if (response.status === 200) {
                    response.json().then(jsonResponse => {
                        resolve(jsonResponse);
                    }).catch(err => {
                        console.log(err);
                        reject(err);
                    });
                } else {
                    reject("[UniversalLivechatService] Error creating chat session => ", response);
                }
            }).catch(err => {
                console.log("[UniversalLivechatService] Error creating chat session => ", err);
                reject(err);
            })
        });
    }

    joinSession(room, guestJid, flowData, changedReason=false) {
        return new Promise((resolve, reject) => {
            fetch(
                Config.chatService.url +
                "/join-session?token=" + this.getToken(),
                {
                    method: "post",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({room: room, guest_jid: guestJid, userData: flowData, changedReason: changedReason})
                }
            ).then(response => {
                if (response.status === 200) {
                    response.json().then(jsonResponse => {
                        resolve(jsonResponse);
                    }).catch(err => {
                        console.log("[UniversalLivechatService] Error while joining session : ", err);
                        reject({"data": {"message": "loginFailed"}})
                    });
                } else {
                    console.log("[UniversalLivechatService] Session does not exist. We need to create a new one");
                    reject({"data": {"message": "loginFailed"}})
                }
            }).catch(err => {
                console.log("[UniversalLivechatService] Error while joining session => ", err);
                reject({"data": {"message": "loginFailed"}})
            })
        });
    }

    getSession(room) {
        return new Promise((resolve, reject) => {
            fetch(Config.chatService.url + "/session?roomId=" + room.id + "&token=" + this.getToken(), {
                method: "get",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                }
            }).then(response => {
                if (response.status === 200) {
                    response.json().then(jsonResponse => {
                        resolve(jsonResponse);
                    }).catch(err => {
                        console.log("[UniversalLivechatService] Error while retrieving session : ", err, "We need to create a new one");
                        reject({"data": {"message": "loginFailed"}})
                    });
                } else {
                    console.log("[UniversalLivechatService] Session does not exist. We need to create a new one");
                    reject({"data": {"message": "loginFailed"}})
                }
            }).catch(err => {
                console.log("[UniversalLivechatService] Error while getting session => ", err);
                reject({"data": {"message": "loginFailed"}})
            })
        });
    }

    getBusinessHours(language) {
        return new Promise((resolve, reject) => {
            fetch(Config.chatService.url + Config.chatService.businessHours+'?language='+ language + "&token=" + this.getToken(), {
                method: 'get',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                response.json().then(jsonResponse => {
                    response.status === 200 ? resolve(jsonResponse) : reject(jsonResponse);
                });
            }).catch(function(err) {
                console.log("[UniversalLivechatService] Error getting availability => ",err);
                reject(err);
            });
        })
    }

    deleteSession = async (roomId, deletionReason = undefined, reasonForChat = undefined, languageRequested = undefined) => {
        fetch(Config.chatService.url + "/delete-session", {
            method: "post",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                roomId: roomId,
                deletionReason: deletionReason,
                reasonForChat: reasonForChat,
                languageRequested: languageRequested
            })
        }).then(() => {
            console.log("[UniversalLivechatService] Session has been deleted");
        }).catch(err => {
            console.log("[UniversalLivechatService] Error trying to delete session => ", err);
        })
    }

    sendInformationMessageToUser = async (roomId, reason, language) => {
        fetch(Config.chatService.url + "/inform-user?token=" + this.getToken(), {
            method: "post",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                roomId: roomId,
                reason: reason,
                language: language
            })
        }).then(() => {
            console.log("[UniversalLivechatService] User has been informed of the reason: ", reason);
        }).catch(() => {
            console.log("[UniversalLivechatService] Error while informing user of the reason: ", reason);
        })
    }
}

export default new UniversalLivechatService();
