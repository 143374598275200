import React from "react";
import I18n from "i18n-react";
import "./LegalText.css";

export default class LegalText extends React.Component {

    render() {
        let legalText = I18n.translate("legal_text").split("%s");
        return (
            <p id="legal-text">
                {legalText[0]}
                <a id="legal-text-link" href={I18n.translate("legal_text_url_disclosure")} target="_blank" rel="noopener noreferrer">{I18n.translate("legal_text_disclosure")}</a>
                {legalText[1]}
                <a id="legal-text-link" href={I18n.translate("legal_text_url_preference_center")} target="_blank" rel="noopener noreferrer">{I18n.translate("legal_text_preference_center")}</a>
                {legalText[2]}
            </p>
        )
    }
}
