import I18n from "i18n-react";
import countries from "i18n-iso-countries";

class I18nService {

    init(){
        this.setLanguage(this.getUsedLanguage());
        countries.registerLocale(require("i18n-iso-countries/langs/"+this.getUsedLanguage()+".json"));
    }

    getUsedLanguage(){
        let language = new URLSearchParams(window.location.search).get("language") || new URLSearchParams(window.location.search).get("Language") || "en";
        language = language.toLowerCase();

        if (language.length > 2) {
            language = language.split("-")[0];
            language = language.split("_")[0];
        }

        if(!this.getLanguages().includes(language)){
            language = "en";
        }

        return language;
    }

    getLanguages(){
        return ["en","fr","de","es"];
    }

    getNativeLanguageName(alpha2code) {
      const nativeLanguages = {
          "fr": "français",
          "en": "English",
          "de": "Deutsch",
          "es": "Español"
      }
      return nativeLanguages[alpha2code];
    };

    setLanguage(language){
        I18n.setTexts(require("../locales/"+language+".json"));
    }

    getSupportedCountriesForSelect() {
        const supportedCountries = ["FR","DE","ES","AT","IT","NL","BE","IE"];
        return supportedCountries.map(alphaCode2 => {
            let dict = {};
            dict["value"] = alphaCode2;
            dict["label"] = countries.getName(alphaCode2, this.getUsedLanguage(), {select: "official"});
            return dict;
        })
    }

    getAllCountriesForSelect() {
        const allCountries = countries.getNames(this.getUsedLanguage());
        return Object.getOwnPropertyNames(allCountries).map((key) => {
            return { label: allCountries[key], value: key };
        });
    }
 }


 export default new I18nService();
