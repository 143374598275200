import React from "react";
import "./LoadingScreen.css";

export default function LoadingScreen(props) {
    return (
        <div className="loader">
            <div className="lds-grid">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <header>{props.message}</header>
        </div>
    );
}
