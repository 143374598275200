import * as React from "react";
import * as AdaptiveCards from "adaptivecards";
import { renderToString } from 'react-dom/server'
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw'
import './AdaptiveCard.css'

const AdaptiveCard = ({ card, actionHandler}) => {
    const cardWrapperRef = React.createRef();

    React.useEffect(() => {
        if (!cardWrapperRef || !card) return;

        const adaptiveCard = new AdaptiveCards.AdaptiveCard();
        adaptiveCard.hostConfig = AdaptiveCard.defaultHostConfig;
        adaptiveCard.hostConfig.containerStyles.default.backgroundColor = "#dbdbdb";
        adaptiveCard.hostConfig.fontFamily = "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n" +
            "    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n" +
            "    sans-serif;"
        adaptiveCard.hostConfig.fontSizes.default = 15
        adaptiveCard.hostConfig.spacing.padding = 0
            AdaptiveCards.AdaptiveCard.onProcessMarkdown = function (content, result) {
            result.outputHtml = renderToString(<ReactMarkdown rehypePlugins={[rehypeRaw]} linkTarget="_blank">{content}</ReactMarkdown>)
            result.didProcess = true;
        };
        adaptiveCard.onExecuteAction = actionHandler;
        adaptiveCard.parse(card);

        cardWrapperRef.current.innerHTML = "";
        adaptiveCard.render(cardWrapperRef.current);
    }, [card, cardWrapperRef, actionHandler]);

    return <div ref={cardWrapperRef} />;
};

export default AdaptiveCard;
